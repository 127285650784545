<template>
  <div>
    <v-row>
      <v-col sm="8" md="5">
        <s-toolbar label="Historial de Accesos" />
        <v-card>
          <v-col>
            <v-row>
              <v-col cols="6">
                <s-date label="Feha de inicio" v-model="begin"></s-date>
              </v-col>
              <v-col cols="6">
                <s-date label="Feha de fin" v-model="end"></s-date>
              </v-col>
              <v-col cols="6" class="s-col-form">
                  <s-toolbar-person noCamera label="Documento*" noEdit v-model="PrsDocumentNumber" @returnPerson="returnPerson($event, item)" />
              </v-col>
              <v-col cols="6" class="s-col-form">
                  <s-text readonly label="Persona" v-model="NtpFullName"></s-text>
              </v-col>
            </v-row>
            <v-row>
              <v-col><v-btn small color="primary" block @click="reporte()"> Generar Reporte</v-btn></v-col>
            </v-row>
          </v-col>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import _sAccessControl from "./../../services/Management/ControlAccess";
import sToolbarPerson from "@/components/Utils/Person/sToolbarPerson";
import SText from '../../components/Utils/SText.vue'
export default {
  name: "AccessControlReport",
  components: {sToolbarPerson, SText},
  props: {
    MltID: {
      type: Number,
      default: null,
    },
  },

  data: () => ({ pvs: "1", dialog: true, item: null, end: null, begin: null, PrsDocumentNumber: null, NtpFullName: null }),
  methods: {
    reporte() {
      _sAccessControl.historyaccess({ beginDate: this.begin, EndDate: this.end, NtpID: this.NtpID }).then((r) => {
        this.$fun.downloadFile(r.data, this.$const.TypeFile.EXCEL, "Reporte de Accesos");
      });
    },
    returnObject(item) {
      this.item = item;
    },
    start() {
      if (this.item != null) this.$emit("start", this.item);
      else this.$fun.alert("Seleccione punto de Vigilancia", "warning");
      //  this.$router.push({ name: 'ACCESS_CONTROL_DETAIL', params: { pv: this.pvs } });
    },
    cancel() {
      this.$emit("cancel");
    },
    returnPerson(value, item) {
        this.NtpFullName = value.NtpFullName;
        this.PrsDocumentNumber = value.PrsDocumentNumber;
        this.NtpID = value.NtpID;
        this.PrsID = value.PrsID;
        this.WkrID = value.WkrID;
    },
  },
  mounted() {
    console.log(this.$route);
  },
};
</script>
